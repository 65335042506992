import * as React from "react"
import {
  createBrowserRouter,
  RouterProvider,
  Link
} from "react-router-dom"
import AddPortal from "../Pages/AddPortal"


const router = createBrowserRouter([
  {
    path: "/addPortal",
    element: <AddPortal />
  },
  {
    path: "/addPortal/:macAddress",
    element: <AddPortal />
  }
])

const Routing = () => {
  return (
    <RouterProvider router={router} />
  )
}

export default Routing

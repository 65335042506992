import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Button, Image, List, Spin, Alert, Popconfirm, Select } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { MyInput, MySelect } from "../../Components";
import { useParams } from "react-router-dom";
import { addPortal } from "../../Shared/apiCalls/addPortals/index";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next"; 
import LanguageSwitcher from '../../Components/LanguageSwitcher';  
import "./index.css";

const AddPortal = () => {
    const { t } = useTranslation(); 
    const [form] = Form.useForm();
    let { macAddress } = useParams();
    const [loading, setLoading] = useState(false);
    const [portals, setPortals] = useState([]);
    const [portalsLoading, setPortalsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [portalType, setPortalType] = useState(null);
    const [usedUrlNos, setUsedUrlNos] = useState([]);
    const [recaptchaValue, setRecaptchaValue] = useState(null);

    const recaptchaRef = React.createRef();

    useEffect(() => {
        console.log("Component mounted. Checking macAddress parameter:", macAddress);
        if (macAddress) {
            console.log("Setting macAddress in form:", macAddress);
            form.setFieldValue("macAddress", macAddress);
            loadPortals(macAddress);
        }
    }, [macAddress, form]);

    const loadPortals = (macAddress) => {
        console.log("Loading portals for macAddress:", macAddress);
        setPortalsLoading(true);
        setError(null);
        addPortal.showPortals(macAddress)
            .then((data) => {
                console.log("Portals fetched successfully:", data);
                setPortals(data || []);
                const usedUrls = data.map(portal => portal.urlNo);
                console.log("Used URL numbers:", usedUrls);
                setUsedUrlNos(usedUrls);
            })
            .catch((err) => {
                console.error("Portallar alınırken bir hata oluştu ", err);
                setError({ message: err || t('Error fetching portals.') });
            })
            .finally(() => {
                console.log("Portals loading completed.");
                setPortalsLoading(false);
            });
    };

    const deletePortalHandler = (portalId) => {
        console.log("Deleting portal with ID:", portalId);
        addPortal.deletePortal(macAddress, portalId)
            .then(() => {
                console.log("Portal deleted successfully. Reloading portals.");
                loadPortals(macAddress);
            })
            .catch((err) => console.error("Error deleting portal:", err));
    };

    const addNewPortal = () => {
        if (!recaptchaValue) {
            console.warn("Recaptcha validation failed.");
            alert(t('recaptcha'));
            return;
        }

        setLoading(true);
        const payload = form.getFieldsValue();
        console.log("Adding new portal with payload:", payload);
        console.log('username:', payload.userName, 'password:', payload.password);

        if (portalType === 3) {
            if (!payload.userName || !payload.password) {
                console.warn("Portal tipi 3 için kullanıcı adı veya şifre eksik.");
                setLoading(false);
                return;
            }
        }

        addPortal.addNewPortal(payload)
            .then((success) => {
                if (success) {
                    console.log("Portal added successfully. Reloading portals.");
                    loadPortals(macAddress);
                }
            })
            .catch((err) => console.error("Error adding new portal:", err))
            .finally(() => {
                console.log("Add portal operation completed.");
                setLoading(false);
            });
    };

    const deleteAllPortals = () => {
        console.log("Deleting all portals for macAddress:", macAddress);
        setLoading(true);
        addPortal.deleteAllPortals(macAddress)
            .then(() => {
                console.log("All portals deleted successfully. Reloading portals.");
                loadPortals(macAddress);
            })
            .catch((err) => console.error("Error deleting all portals:", err))
            .finally(() => {
                console.log("Delete all portals operation completed.");
                setLoading(false);
            });
    };

    const limitUrl = (url) => {
        const maxLength = 40;
        const limitedUrl = url.length > maxLength ? `${url.slice(0, maxLength)}...` : url;
        console.log("Limiting URL to:", limitedUrl);
        return limitedUrl;
    };

    const handlePortalTypeChange = (value) => {
        console.log("Portal type changed to:", value);
        setPortalType(value);
    };

    const handleRecaptchaChange = (value) => {
        console.log("Recaptcha value changed:", value);
        setRecaptchaValue(value);
    };

    return (
        <div className="cover py-5">
            <div className="language-switcher-container">
                <LanguageSwitcher />  
            </div>

            <Form form={form} layout="vertical" onFinish={addNewPortal} className="add-portal-form">
                <Row gutter={[16, 16]} justify="center">
                    <Col span={24} className="center mb-4">
                        <Image src="/assets/images/logo.jpg" alt="my-enjoy-tv" width="250px" style={{ borderRadius: "3px" }} />
                    </Col>

                    <Col xs={24} sm={12} md={12} lg={12}>
                        <MyInput
                            size="large"
                            label={t('macAddress')}
                            name="macAddress"
                            placeholder="00:B0:D0:63:C2:46"
                            required
                            message={t("macAddress")}
                        />
                    </Col>

                    <Col xs={24} sm={12} md={12} lg={12}>
                        <MySelect
                            label={t('portalType')}
                            name="methodType"
                            required
                            message={t('portalType')}
                            placeholder={t('portalType')}
                            options={[{ name: "M3U", id: 1 }, { name: "Stalker", id: 2 }, { name: "Xtream Code API", id: 3 }]}
                            size="large"
                            onChange={handlePortalTypeChange}
                        />
                    </Col>

                    <Col xs={24} sm={12} md={12} lg={12}>
                        <MyInput label={t('name')} name="name" placeholder="e.g. my m3u" required message={t("name")} size="large" />
                    </Col>

                    <Col xs={24} sm={12} md={12} lg={12}>
                        <MyInput label={t('url')} name="url" placeholder="e.g. http://example.com" required message={t("url")} size="large" />
                    </Col>

                    <Col xs={24} sm={12} md={12} lg={12}>
                        <MySelect
                            label={t('urlNo')}
                            name="urlNo"
                            required
                            message={t('selectUrlNo')}
                            placeholder={t('selectUrlNo')}
                            options={[1, 2, 3, 4, 5].map((num) => ({
                                name: num.toString(),
                                id: num,
                                disabled: usedUrlNos.includes(num),
                            }))}
                            size="large"
                        />
                    </Col>

                    {portalType === 3 && (
                        <>
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <MyInput label={t('username')} name="userName" placeholder={t('username')} required message={t("username")} size="large" />
                            </Col>

                            <Col xs={24} sm={12} md={12} lg={12}>
                                <MyInput label={t('password')} name="password" placeholder={t('password')} required message={t("password")} size="large" />
                            </Col>
                        </>
                    )}

                    <Col span={24} className="center mt-4">
                        <ReCAPTCHA
                            sitekey="6LdUVZ8qAAAAADELoGV21UJfP6f2QinlLrBjwSXU"
                            onChange={handleRecaptchaChange}
                            size="normal"
                        />
                    </Col>

                    <Col span={24} className="center mt-4">
                        <Button size="large" type="primary" htmlType="submit" loading={loading} block>
                            {t('addPortal')}
                        </Button>
                    </Col>

                    {error && (
                        <Col span={24} className="mt-4">
                            <Alert message={error.message} type="error" showIcon />
                        </Col>
                    )}

                    <Col span={24} className="mt-4">
                        {portalsLoading ? (
                            <Spin size="large" />
                        ) : (
                            <List
                                header={<div>{t('currentPortals')}</div>}
                                bordered
                                dataSource={portals}
                                renderItem={(item) => (
                                    <List.Item
                                        actions={[<Popconfirm title={t('confirmDelete')} onConfirm={() => deletePortalHandler(item.id)} okText="Yes" cancelText="No"><DeleteOutlined style={{ color: "red" }} /></Popconfirm>]} >
                                        <div>
                                            {item.urlNo}. {item.name}
                                        </div>
                                        
                                    </List.Item>
                                )}
                            />
                        )}
                    </Col>

                    <Col span={24} className="center mt-4" style={{ marginTop: "40px" }}>
                        <Popconfirm
                            title={t('deleteAll')}
                            onConfirm={deleteAllPortals}
                            okText={t('okText')}
                            cancelText={t('cancelText')}
                        >
                            <Button
                                size="large"
                                type="primary"
                                danger
                                style={{
                                    backgroundColor: "red",
                                    borderColor: "white",
                                    color: "white",
                                    height: "50px",
                                    width: "150px",
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                }}
                                htmlType="button"
                                loading={loading}
                            >
                                {t('deleteAll')}
                            </Button>
                        </Popconfirm>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default AddPortal;

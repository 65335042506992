import {Form, Select, Typography} from 'antd';
export const MySelect = ({withoutForm,name,label,mode,disabled, showSearch,required,message,value,options, ...props}) => {
  return (
    withoutForm?
      <Select 
        maxTagCount= 'responsive'
        value={value || ''} 
        mode={mode || ''} 
        disabled={disabled || false} 
        {...props}
      >
          {
              options?.map(opt=><Select.Option value={opt?.id} key={opt?.id}>{opt?.name}</Select.Option>)
          }
      </Select>
      :
      showSearch ?
      <Form.Item
        name={name}
        label={<Typography.Text strong>{label}</Typography.Text>}
        rules={[
            {
            required,
            message,
            },
        ]}
      >
        <Select 
          value={value || ''} 
          mode={mode || ''} 
          disabled={disabled || false} 
          maxTagCount= 'responsive'
          {...props}
          >
            {
                options?.map(opt=><Select.Option value={opt?.name} key={opt?.id}>{opt?.name}</Select.Option>)
            }
        </Select>
      </Form.Item>  
      :
      <Form.Item
        name={name}
        label={<Typography.Text strong>{label}</Typography.Text>}
        rules={[
            {
            required,
            message,
            },
        ]}
      >
        <Select 
          value={value || ''} 
          mode={mode || ''} 
          disabled={disabled || false} 
          maxTagCount= 'responsive'
          {...props}
          >
            {
                options?.map(opt=><Select.Option value={opt?.id} key={opt?.id}>{opt?.name}</Select.Option>)
            }
        </Select>
      </Form.Item>  
  )
}
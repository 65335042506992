// src/i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from './locales/en/translation.json'; // İngilizce çeviri dosyasını ekleyin
import translationTR from './locales/tr/translation.json'; // Türkçe çeviri dosyasını ekleyin

i18n
  .use(initReactI18next) // react-i18next kullanıyoruz
  .init({
    resources: {
      en: {
        translation: translationEN,
      },
      tr: {
        translation: translationTR,
      },
    },
    lng: "tr", // Varsayılan dil
    fallbackLng: "en", // Dil bulunamazsa geçerli olacak dil
    interpolation: {
      escapeValue: false, // React zaten XSS güvenliği sağlar
    },
  });

export default i18n;

import { message } from "antd";
import { domainUrl } from "../../constants/";

const addNewPortal = async (payload) => {
    try {
        if (payload.portalType === "3") {
            console.log("Kullanıcı adı:", payload.userName);
            console.log("Şifre:", payload.password);
            console.log("Payload içeriği:", payload);
            if (!payload.userName || !payload.password) {
                throw new Error("Portal tipi 3 için kullanıcı adı veya şifre eksik.");
            }
        }

        const updatedPayload = {
            ...payload,
            methodType: payload.portalType === "3" ? 3 : payload.methodType,
            portalType: payload.portalType,
            urlNo: payload.urlNo, 
        };

        console.log("[addNewPortal] Gönderilen payload:", updatedPayload);

        const response = await fetch("https://controlmyenjoy.site/api/addPortal", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(updatedPayload),
        });

        console.log("[addNewPortal] API yanıtı status:", response.status);

        const result = await response.json();
        console.log("[addNewPortal] API yanıtı (JSON):", result);

        if (response.ok && result?.success) {
            message.success(result?.message || "Portal başarıyla eklendi.");
            console.log("[addNewPortal] Başarılı işlem:", result?.message);
            return 1;
        } else {
            console.error("[addNewPortal] Başarısız işlem:", result?.message);
            message.error(result?.message || "Portal ekleme başarısız oldu.");
            throw new Error(result?.message || "Unknown error occurred.");
        }
    } catch (error) {
        console.error("[addNewPortal] Hata:", error.message);
        message.error(error.message || "Bilinmeyen bir hata oluştu.");
        return 0;
    }
};

const showPortals = async (macAddress) => {
    try {
        console.log("[showPortals] Gelen macAddress:", macAddress);

        const response = await fetch(
            `https://controlmyenjoy.site/api/showAllPortals?macAddress=${macAddress}`,
            {
                method: "POST",
                headers: { "Content-Type": "application/json" },
            }
        );

        console.log("[showPortals] API yaniti status:", response.status);

        const result = await response.json();
        console.log("[showPortals] API yanıtı (JSON):", result);

        if (response.ok && result?.success) {
            const sortedPortals = result?.portals.sort((a, b) => a.urlNo - b.urlNo);
            console.log("[showPortals] Sıralanmış portallar:", sortedPortals);
            return sortedPortals.map((portal) => ({
                ...portal,
                urlNo: portal.urlNo, 
            }));
        } else {
            console.error("[showPortals] Başarısız işlem:", result?.message);
            throw new Error(result?.message || "Portallar yüklenemedi.");
        }
    } catch (error) {
        console.error("[showPortals] Hata:", error.message);
        message.error(error.message || "Portallar yüklenirken bir hata oluştu.");
        return [];
    }
};

const deletePortal = async (macAddress, portalId) => {
    try {
        const payload = { macAddress, id: portalId };
        console.log("[deletePortal] Silme isteği gönderilen payload:", payload);

        const response = await fetch(
            `https://controlmyenjoy.site/api/deletePortal/${portalId}`,
            {
                method: "DELETE",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(payload),
            }
        );

        console.log("[deletePortal] API yanıtı status:", response.status);

        const result = await response.json();
        console.log("[deletePortal] API yanıtı (JSON):", result);

        if (response.ok && result?.success) {
            message.success(result?.message || "Portal başarıyla silindi.");
            console.log("[deletePortal] Başarılı işlem:", result?.message);
            return 1;
        } else {
            console.error("[deletePortal] Başarısız işlem:", result?.message);
            throw new Error(result?.message || "Portal silme başarısız oldu.");
        }
    } catch (error) {
        console.error("[deletePortal] Hata:", error.message);
        message.error(error.message || "Bilinmeyen bir hata oluştu.");
        return 0;
    }
};

const deleteAllPortals = async (macAddress) => {
    try {
        console.log("[deleteAllPortals] İşlem başlatıldı. macAddress:", macAddress);

        const portals = await showPortals(macAddress);
        console.log("[deleteAllPortals] Bulunan portallar:", portals);

        if (portals.length === 0) {
            message.info("Silinecek portal bulunamadı.");
            console.log("[deleteAllPortals] Portal listesi boş.");
            return;
        }

        for (const portal of portals) {
            console.log("[deleteAllPortals] Siliniyor portal:", portal);
            await deletePortal(macAddress, portal.id);
        }

        message.success("Tüm portallar başarıyla silindi.");
        console.log("[deleteAllPortals] Tüm portallar başarıyla silindi.");
    } catch (error) {
        console.error("[deleteAllPortals] Hata:", error.message);
        message.error(`Portallar silinirken bir hata oluştu: ${error.message}`);
    }
};

const updatePortal = async (payload) => {
    try {
        if (!payload.id) {
            throw new Error("Portal ID eksik. Güncelleme işlemi için bir ID gerekli.");
        }

        if (payload.methodType === 3) {
            if (!payload.url || !payload.userName || !payload.password || !payload.macAddress) {
                throw new Error(
                    "Portal tipi 3 için URL, kullanıcı adı, şifre ve MAC adresi gereklidir."
                );
            }
        } else if (payload.methodType === 1 || payload.methodType === 2) {
            if (!payload.url || !payload.macAddress) {
                throw new Error(
                    "Portal tipi 1 veya 2 için URL ve MAC adresi gereklidir."
                );
            }
        } else {
            throw new Error("Geçersiz portal türü.");
        }

        const updatedPayload = {
            ...payload,
            urlNo: payload.urlNo, // URL No güncelleme için eklendi
        };

        const response = await fetch("https://controlmyenjoy.site/api/updatePortal", {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(updatedPayload),
        });

        console.log("[updatePortal] API yanıtı status:", response.status);

        const result = await response.json();
        console.log("[updatePortal] API yanıtı (JSON):", result);

        if (response.ok && result?.success) {
            message.success(result?.message || "Portal başarıyla güncellendi.");
            console.log("[updatePortal] Başarılı işlem:", result?.message);
            return 1;
        } else {
            console.error("[updatePortal] Başarısız işlem:", result?.message);
            message.error(result?.message || "Portal güncelleme başarısız oldu.");
            throw new Error(result?.message || "Unknown error occurred.");
        }
    } catch (error) {
        console.error("[updatePortal] Hata:", error.message);
        message.error(error.message || "Bilinmeyen bir hata oluştu.");
        return 0;
    }
};

export const addPortal = { 
    addNewPortal, 
    showPortals, 
    deletePortal, 
    deleteAllPortals, 
    updatePortal 
};

import React from "react";
import { Select } from "antd";
import { useTranslation } from "react-i18next";
import trFlag from "../Components/assets/tr-flag.jpg";
import ukFlag from "../Components/assets/uk-flag.jpg";
import "./LanguageSwitcher.css";

const LanguageSwitcher = () => {
    const { i18n } = useTranslation();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    const languageOptions = [
        {
            label: (
                <div style={{ display: "flex", alignItems: "center" }}>
                    <img src={trFlag} alt="Türkçe" className="flag-icon" style={{ marginRight: 8 }} />
                    Türkçe
                </div>
            ),
            value: "tr"
        },
        {
            label: (
                <div style={{ display: "flex", alignItems: "center" }}>
                    <img src={ukFlag} alt="English" className="flag-icon" style={{ marginRight: 8 }} />
                    English
                </div>
            ),
            value: "en"
        }
    ];

    return (
        <Select
            defaultValue="tr"  
            style={{ width: 120 }}
            onChange={changeLanguage}
            options={languageOptions}
            className="language-select"
        />
    );
};

export default LanguageSwitcher;
